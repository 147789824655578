<template>
  <div class="new-order-details" v-if="order">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 pe-1">
          <div class="sticky-track">
            <div class="sticky">
              <div class="panel-container">
                <div class="panel">
                  <div class="panel-top">
                    <div class="row">
                      <div class="col">
                        <span
                          class="capitalized order-status"
                          :class="{
                            'gray': order.status == 'pending',
                            'yellow': order.status == 'awaiting_payment_confirmation',
                            'blue': order.status == 'processing',
                            'green': order.status == 'completed' || order.status == 'refunded',
                            'red': order.status == 'failed' || order.status == 'on_hold' || order.status == 'cancelled',
                          }"
                        >{{order.status | formatStatus}}</span> Order
                        <span class="bold">
                          <span v-if="order.number">#</span>
                          {{order.number}}
                        </span>
                        for
                        <span class="bold">£{{ order.meta.payment_amount.cents | money }}</span>
                      </div>
                      <div class="col-auto">
                        <div class="pale text-small">{{ order.created_at | formattedLongDate }} at {{ order.created_at | formattedHour }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="panel-center">
                    <div class="product py-3" v-for="(item, key) in order.line_items" :key="key">
                      <div class="row align-items-center">
                        <div class="col-auto">
                          <img :src="image(item.meta.image, 150)" />
                        </div>
                        <div class="col">
                          <div class="row">
                            <div class="col">
                              <div class="d-flex flex-column">
                                <div class="text-uppercase bold">{{ item.meta.name }}</div>
                                <div v-for="(i, k) in item.meta.aspects" :key="k" class="d-flex text-small">
                                  <div>{{ i.name }}:</div>
                                  <div>{{ i.option }}</div>
                                </div>
                              </div>
                            </div>
                            <div class="col-auto d-flex">
                              <div class v-if="order.meta.discount_type == 'bulk'">
                                £{{ item.meta.bulk_price | money }}
                                <span class="bulkprice">(original price £{{ item.meta.price | money }})</span>
                              </div>
                              <div class v-else>£{{ item.meta.price | money }}</div>
                              <div class="text-small ms-1">x {{ item.quantity }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="panel-bottom">
                    <div class="row">
                      <div class="col"></div>
                      <div class="col-auto">
                        <table style="text-align: right;">
                          <tbody>
                            <tr class>
                              <td class="pe-5">Subtotal</td>
                              <td>£{{ order.meta.cart_before_discounts.cents | money }}</td>
                            </tr>
                            <tr class>
                              <td class="pe-5">Shipping ({{order.shipping_method}})</td>
                              <td>
                                <span v-if="order.meta.shipping_costs.cents > 0">£{{ order.meta.shipping_costs.cents | money }}</span>
                                <span v-else>FREE</span>
                              </td>
                            </tr>
                            <tr class v-if="order.meta.cart_before_discounts.cents !== order.meta.cart_after_discounts.cents">
                              <td class="pe-5">
                                Discount (
                                <span v-if="order.meta.discount_type == 'coupon'">
                                  <span class="text-uppercase" v-for="coupon,key in order.meta.coupons" :key="key">{{coupon}}</span>
                                </span>
                                <span v-if="order.meta.discount_type == 'bulk'">
                                  <span>Bulk</span>
                                </span>
                                )
                              </td>
                              <td>£{{ discountAmount | money }}</td>
                            </tr>
                            <tr>
                              <td class="pe-5 bold">Order Total</td>
                              <td class="bold">£{{ order.meta.payment_amount.cents | money }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel-container" v-if="order.payment">
                <div class="panel">
                  <div class="panel-top">
                    <div class="row">
                      <div class="col">
                        <div class="bold">PAYMENT DETAILS</div>
                        <div class="row">
                          <div class="col-3">Provider:</div>
                          <div class="col-6">
                            <img v-if="order.payment.provider == 'stripe' || order.payment.provider == 'paypal' || order.payment.provider == 'credit' " :src="images[order.payment.provider]" height="20px" />
                          </div>
                        </div>
                        <div class="row" v-if="order.payment.intent">
                          <div class="col-3">Ref:</div>
                          <div class="col-6">
                            <div v-if="order.payment.provider == 'stripe'">
                              <a :href="'https://dashboard.stripe.com/payments/' + order.payment.intent">{{ order.payment.intent }}</a>
                            </div>
                            <div v-else>{{ order.payment.intent }}</div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-3">Payment amount:</div>
                          <div class="col-6">
                            £{{ order.payment.amount | money }}
                            <span v-if="order.meta.used_credit_to_pay && order.payment.provider != 'credit'">({{ order.payment.provider }}) + £{{order.meta.credit_usage_for_this_order.cents | money}} (credit) = £{{order.meta.payment_amount.cents | money}}</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-3">Paid at:</div>
                          <div class="col-6">
                            <span v-if="order.payment.paid_at" class="badge bg-success">{{ order.payment.paid_at | dateFromTimestamp}}</span>
                            <span v-else>-</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="panel-bottom no-top-border">
                    <div class="row" v-if="order.payment">
                      <div v-if="!paymentsReady">
                        <div class="spinner-border spinner-border-sm ms-2" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                      <div class="col" v-else>
                        <div class>
                          <table class="table-container table align-middle" v-if="payments.charges.length">
                            <tbody>
                              <tr v-for="charge,k in payments.charges" :key="k" :class="{'pale': !charge.paid}">
                                <td>
                                  <span v-if="charge.paid" title="Payment Succeeded">
                                    <svg class="status-icon green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                    </svg>
                                  </span>
                                  <span v-else title="Payment Failed">
                                    <svg class="status-icon red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                      <path
                                        d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"
                                      />
                                    </svg>
                                  </span>
                                </td>
                                <td>
                                  <img :src="images[charge.provider]" width="40px" />
                                </td>
                                <td>
                                  <div class="row">
                                    <div class="col-auto">
                                      <div class="d-flex flex-column">
                                        <span v-if="charge.provider == 'stripe'">
                                          <img :src="getCardSrc(charge.payment_method_details.card.brand)" :alt="charge.payment_method_details.card.brand" width="30px" />
                                          **** {{ charge.payment_method_details.card.last4 }}
                                        </span>
                                        <div class="hidden">
                                          <span class="text-small">{{ charge.created | dateFromTimestamp }}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="d-flex flex-column">
                                        <span :class="{'bold': charge.paid, '': !charge.paid}">£{{ charge.amount | money }}</span>
                                        <span class="text-small hidden" v-if="charge.details">{{ charge.details | formatStatus}}</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div v-else>No transactions</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 ps-1">
          <div class="sticky-track">
            <div class="sticky">
              <div class="panel-container">
                <div class="panel">
                  <div class="panel-single">
                    <div class="row">
                      <div class="col-auto" v-if="order.remote_id">
                        <a :href="'https://app.metorik.com/orders/'+order.remote_id" class="vave-btn btn-small" target="_blank">
                          <img class="me-1" :src="images.metorik" width="14px" /> Open in Metorik
                        </a>
                      </div>
                      <div class="col-auto">
                        <button class="vave-btn btn-small btn-purple" @click="pushToWC()" :disabled="pushToWCDisabled">Push To WooCommerce</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel-container">
                <div class="panel">
                  <div class="panel-top">
                    <div class="row">
                      <div class="col-12">
                        <router-link class="bold blue" style="text-decoration:none;" v-if="order.user" :to="'/customers/' +  order.user.id ">{{order.billing_address.first_name}} {{order.billing_address.last_name}}</router-link>
                        <span class="bold" v-else>{{order.billing_address.first_name}} {{order.billing_address.last_name}}</span>
                      </div>
                      <div class="col-12">
                        <table>
                          <tbody>
                            <tr>
                              <td class="pale" style="width: 140px;">Account Type</td>
                              <td class="pale bold capitalized" v-if="order.user">{{ order.user.account_type }}</td>
                            </tr>
                            <tr>
                              <td class="pale">Registered</td>
                              <td class="pale bold" v-if="order.user && order.user.created_at && order.user.is_registered">
                                Yes
                                <span v-if="order.user && order.user.created_at">({{order.user.created_at | formattedDate}})</span>
                              </td>
                              <td v-else>No</td>
                            </tr>
                            <tr>
                              <td class="pale">Order Marketing</td>
                              <td class="pale bold" v-if="order.marketing_opt_in">Yes</td>
                              <td v-else>No</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="panel-bottom no-top-border">
                    <div class="row">
                      <div class="col-12 pb-2">
                        <div class="d-flex">
                          <svg class="pale-icon me-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                          </svg>
                          <div class="ps-1 bold">{{order.email}}</div>
                        </div>
                        <div class="d-flex">
                          <svg class="pale-icon me-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                              d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"
                            />
                          </svg>
                          <div class="ps-1 bold">{{order.phone}}</div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class>
                          <div class="row">
                            <div>
                              <div class="d-flex pt-2">
                                <svg class="pale-icon me-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" style="width: 10px">
                                  <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                                </svg>
                                <div class="ps-1 bold">Billing Address</div>
                              </div>
                              <div class="pale ps-4">
                                <div>{{ order.billing_address.first_name }}</div>
                                <div>{{ order.billing_address.last_name }}</div>
                                <div>{{ order.billing_address.company }}</div>
                                <div>{{ order.billing_address.address_1 }}</div>
                                <div>{{ order.billing_address.address_2 }}</div>
                                <div>{{ order.billing_address.postcode }}</div>
                                <div>{{ order.billing_address.city }}</div>
                                <div>{{ order.billing_address.country }}</div>
                                <div>{{ order.billing_address.email }}</div>
                                <div>{{ order.billing_address.phone }}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class>
                          <div class="d-flex pt-2">
                            <svg class="pale-icon me-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" style="width: 10px">
                              <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                            </svg>
                            <div class="bold ps-1">Delivery Address</div>
                          </div>
                          <div class="pale ps-4">
                            <div>{{ order.shipping_address.first_name }}</div>
                            <div>{{ order.shipping_address.last_name }}</div>
                            <div>{{ order.shipping_address.company }}</div>
                            <div>{{ order.shipping_address.address_1 }}</div>
                            <div>{{ order.shipping_address.address_2 }}</div>
                            <div>{{ order.shipping_address.postcode }}</div>
                            <div>{{ order.shipping_address.city }}</div>
                            <div>{{ order.shipping_address.country }}</div>
                            <div>{{ order.shipping_address.email }}</div>
                            <div>{{ order.shipping_address.phone }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel-container">
                <div class="panel">
                  <div class="panel-single">
                    <div class="pale">
                      <div class v-if="!order.tracking">
                        <div class="pale">TRACKING INFORMATION</div>
                        <div v-if="!trackingReady">
                          <div class="spinner-border spinner-border-sm ms-2" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                        <div v-else-if="tracking.carrier">
                          <div class="d-flex align-items-end mt-2">
                            <img v-if="tracking.carrier_slug == 'dpd' || tracking.carrier_slug == 'royal-mail'" :src="images[tracking.carrier_slug.replace('-', '')]" width="50px" />
                            <span class="ms-4" v-if="tracking.carrier_service">{{tracking.carrier_service}}</span>
                            <a class="ms-3" :href="tracking.tracking_url" target="_blank">{{ tracking.tracking_number }}</a>
                          </div>
                        </div>
                        <div v-else-if="tracking.error" class="error pt-2">
                          <i class="fas fa-exclamation-triangle"></i>
                          {{tracking.error}}
                        </div>
                      </div>

                      <div v-if="order.tracking">
                        <div class="pale">TRACKING INFORMATION</div>
                        <div class="col">
                          <div class="d-flex align-items-end mt-2">
                            <img v-if="order.tracking.carrier_slug == 'dpd' || order.tracking.carrier_slug == 'royal-mail'" :src="images[order.tracking.carrier_slug.replace('-', '')]" width="50px" />
                            <span class="ms-4" v-if="order.tracking.carrier_service">{{order.tracking.carrier_service}}</span>
                            <a class="ms-3" :href="order.tracking.url" target="_blank">{{ order.tracking.number }}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-5">
        <div class="col-12">
          <div class="panel-container">
            <div class="panel">
              <div class="panel-top">
                <div class="row">
                  <div class="col-5">
                    <table class="table table-sm pale">
                      <tbody>
                        <tr>
                          <th colspan="2">SOURCE</th>
                        </tr>
                        <tr>
                          <td style="width:120px" class="bold">Referrer</td>
                          <td>{{order.meta.utm.referer}}</td>
                        </tr>
                        <tr>
                          <td class="bold">Source Type</td>
                          <td>{{order.meta.utm.source_type}}</td>
                        </tr>
                        <tr>
                          <td class="bold">Source</td>
                          <td>{{order.source}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-7">
                    <table class="table table-sm pale">
                      <tbody>
                        <tr>
                          <th colspan="2">UTM</th>
                        </tr>
                        <tr>
                          <td style="width:250px" class="bold">Source</td>
                          <td>{{order.meta.utm.utm_source}}</td>
                        </tr>
                        <tr>
                          <td class="bold">Medium</td>
                          <td>{{order.meta.utm.utm_medium}}</td>
                        </tr>
                        <tr>
                          <td class="bold">Campaign</td>
                          <td>{{order.meta.utm.utm_campaign}}</td>
                        </tr>
                        <tr>
                          <td class="bold">Content</td>
                          <td>{{order.meta.utm.utm_content}}</td>
                        </tr>
                        <tr>
                          <td class="bold">Term</td>
                          <td>{{order.meta.utm.utm_term}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="panel-bottom no-top-border">
                <div class="row">
                  <div class="col-12">
                    <div class="paler text-small text-start" v-if="order.meta.client['user-agent']">
                      <span class="bold pe-1">IP Address:</span>
                      <span>{{order.meta.client.ip}}</span>
                      <span class="bold px-1">User Agent:</span>
                      <span>{{order.meta.client['user-agent']}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12" v-if="order.sticky_id">
          <div class="panel-container">
            <div class="panel">
              <div class="panel-single">
                <events-feed :sticky-id="order.sticky_id" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../http.js";
import { formatValue } from "../formatters";
import EventsFeed from "./StickyEvents/Feed.vue";
import moment from "moment";
import imageProxy from "./../imageProxy";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pushToWCDisabled: false,
      images: {
        paypal: require("./../assets/paypal.png"),
        stripe: require("./../assets/stripe.png"),
        credit: require("./../assets/credit.png"),
        royalmail: require("./../assets/royal-mail.png"),
        dpd: require("./../assets/dpd.png"),
        metorik: require("./../assets/metorik.png"),
      },
      paymentsReady: false,
      payments: [],
      trackingReady: false,
      tracking: {},
      order: null,
    };
  },
  components: { EventsFeed },
  mounted() {
    this.getOrder();
  },
  filters: {
    dateFromTimestamp(value) {
      let date = new Date(value * 1000);
      return (
        date.toLocaleDateString("en-UK") +
        " " +
        date.toLocaleTimeString("en-UK")
      );
    },
    formattedLongDate(value) {
      return value ? moment(String(value)).format("DD MMMM YYYY") : "";
    },
    formattedHour(value) {
      return value ? moment(String(value)).format("HH:mm") : "";
    },
    formattedDate(value) {
      let date = new Date(value);
      return (
        date.toLocaleDateString("en-UK") +
        " " +
        date.toLocaleTimeString("en-UK")
      );
    },
    money(value) {
      return (Math.round(value) / 100).toFixed(2);
    },
    formatStatus(value) {
      return value.replace(/_/g, " ");
    },
  },
  computed: {
    discountAmount() {
      return (
        this.order.meta.cart_after_discounts.cents -
        this.order.meta.cart_before_discounts.cents
      );
    },
  },
  methods: {
    image: imageProxy.image,
    formatValue,
    getOrder() {
      axios.get("/api/admin/orders/" + this.id).then((response) => {
        this.$emit("orderReady");
        if (response.data.data) {
          this.order = response.data.data;
          this.pushToWCDisabled =
            this.order.number != "" && this.order.number != null;
          if (!this.order.tracking) {
            // Fetch the order tracking from Veeqo and save it to the order
            axios.get("/api/admin/orders/" + this.id + "/tracking").then(
              (response) => {
                if (response.data.data) {
                  this.tracking = response.data.data;
                  this.trackingReady = true;
                }
              },
              (response) => {
                if (response.response.data && response.response.data.error) {
                  this.tracking.error = response.response.data.error;
                  this.trackingReady = true;
                  return;
                }
                this.tracking.error = "Could not load tracking data";
                this.trackingReady = true;
              }
            );
          }
        }
      });

      axios.get("/api/admin/orders/" + this.id + "/payments").then(
        (response) => {
          if (response.data.data) {
            this.payments = response.data.data;
            this.paymentsReady = true;
          }
        },
        () => {}
      );
    },
    pushToWC() {
      if (this.pushToWCDisabled) {
        return;
      }
      if (
        !confirm(
          'Are you sure you want to push this order to WooCommerce? To do so, the order has to have status "processing" and not have a remote number/remote_id '
        )
      ) {
        return;
      }
      this.pushToWCDisabled = true;
      axios
        .post("/api/admin/orders/" + this.id + "/push-to-woocommerce")
        .then(
          (response) => {
            this.$toast.success("Order pushed to WC");
            console.log(response.data);
          },
          (e) => {
            this.$toast.error(
              "Error pushing order to WC: " + e.response.data.error
            );
          }
        )
        .finally(() => {
          setTimeout(() => {
            this.pushToWCDisabled = false;
          }, 2000);
        });

      axios
        .get("/api/admin/orders/" + this.id + "/payments")
        .then((response) => {
          if (response.data.data) {
            this.payments = response.data.data;
            this.paymentsReady = true;
          }
        });
    },
    getCardSrc(brand) {
      let availableBrands = [
        "amex",
        "default",
        "discover",
        "diners",
        "jcb",
        "maestro",
        "mastercard",
        "unionpay",
        "visa",
      ];

      if (!availableBrands.includes(brand)) {
        brand = "default";
      }

      return "/cards/" + brand + ".svg";
    },
  },
};
</script>

<style lang="scss" scoped>
.new-order-details {
  color: #596577;
  background: #f1f5f9;
  font-size: 0.9em;
  .pale {
    color: #8792a7;
    fill: #8792a7;
  }

  .paler {
    color: #b6becc;
  }
  .dark-pale {
    color: #596577;
  }

  .order-status {
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    background: #999999;
    &.gray {
      background: #999999;
    }
    &.green {
      background: #00a65a;
    }
    &.blue {
      background: #3498db;
    }
    &.red {
      background: #dd4f4f;
    }
    &.yellow {
      background: #feb303ce;
    }
  }

  .table {
    &.pale {
      // make horizontal lines paler
      tr:not(:first-child) {
        border-bottom: 1px solid #f3f3f3;
      }
    }
  }
  .bold {
    font-weight: 500;
    &.blue {
      color: #3498db;
    }
  }

  .status-icon {
    width: 16px;
  }

  .hidden {
    height: 0;
    overflow: hidden;
    transition: 0.2s;
    opacity: 0;
  }
  tbody tr {
    &:hover {
      .hidden {
        height: 22px;
        transition: 0.2s;
        opacity: 1;
      }
    }
  }
}

.order-details {
  background: white;
  padding: 10px;
  .bold {
    font-weight: bold;
  }
  .text-small {
    font-size: 0.8em;
  }
  .text-uppercase {
    text-transform: uppercase;
  }
  .align-right {
    text-align: right;
  }

  address {
    border: 1px solid rgb(235, 235, 235);
    padding: 10px;
    border-radius: 5px;
  }
  .bulkprice {
    font-size: 0.8em;
    font-weight: normal;
  }
}
</style>